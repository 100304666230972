import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Scorecard.css";
import Placeholder from "../utils/Placeholder";

const Scorecard = () => {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
const [lastYear, setLastYear] = useState(currentYear - 1);

// Handler to update years based on navigation
const navigateYears = (direction) => {
  if (direction === "back") {
    setCurrentYear((prev) => {
      const newYear = prev - 1;
      setLastYear(newYear - 1);
      return newYear;
    });
  } else if (direction === "forward") {
    setCurrentYear((prev) => {
      const newYear = prev + 1;
      setLastYear(newYear - 1);
      return newYear;
    });
  }
};

  const [vipsData, setVipsData] = useState([]);
  const [associatesData, setAssociatesData] = useState([]);
  const [alpData, setAlpData] = useState([]);
  const [hiresData, setHiresData] = useState([]);
  const [subAgentData, setSubAgentData] = useState([]);
  const [selectedCellData, setSelectedCellData] = useState(null); // New state
  const [selectedTableDetails, setSelectedTableDetails] = useState({}); // Track details per table
  const [hierarchyData, setHierarchyData] = useState([]);
  const [filteredHierarchyData, setFilteredHierarchyData] = useState([]); // Filtered hierarchy data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const storedAgnName = localStorage.getItem("agnName");
  const userRole = localStorage.getItem("userRole")?.toUpperCase();
  const queryRole = (userRole === "RGA" || userRole === "SGA") ? "MGA" : userRole;
    const initialSelectedMga = (userRole === "MGA" || userRole === "RGA") ? storedAgnName : "";
  const [selectedMga, setSelectedMga] = useState(initialSelectedMga);
  const allowedRoles = ["MGA", "RGA", "SGA"];
  const defaultAlpTab = (userRole === "MGA" || userRole === "RGA" || userRole === "SGA" || userRole === "Admin") ? "LVL_3" : "LVL_1";
  const [selectedAlpTab, setSelectedAlpTab] = useState(defaultAlpTab);
    const [rawAlpData, setRawAlpData] = useState([]);
    const permissions = localStorage.getItem("permissions"); // e.g. "Admin", "Partner", "Staff", etc.
    const showAriasOrganization = userRole === "SGA" || ["Admin", "Partner", "Staff"].includes(permissions);
    
  const effectiveAgnName =
    (userRole === "MGA" || userRole === "RGA") && selectedMga
      ? selectedMga
      : storedAgnName;
    const [selectedView, setSelectedView] = useState("a"); // Track the selected view
  const [uniqueMgas, setUniqueMgas] = useState([]); // State for MGA dropdown options
  
  useEffect(() => {
    // Fetch unique MGA options from the API endpoint
    axios
      .get("https://ariaslogin-4a95935f6093.herokuapp.com/api/get-unique-mgas")
      .then((res) => {
        if (res.data.success) {
          const mgas = res.data.data; // Array of objects containing lagnname
          setUniqueMgas(mgas);
          // If storedAgnName is not in the fetched list, default to empty string
          if (!mgas.some((mga) => mga.lagnname === storedAgnName)) {
            setSelectedMga("");
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching unique MGAs:", err);
      });
  }, []);
  
  const toggleView = (view) => {
    setSelectedView(view);
  };

  const formatName = (name) => {
    if (!name) return null;
    const parts = name.split(" ");
    if (parts.length === 4) {
      const [last, first, middle, suffix] = parts;
      return `${first} ${middle} ${last} ${suffix}`;
    }
    if (parts.length === 3) {
      const [last, first, middle] = parts;
      return `${first} ${last} `;
    }
    return name; // Return as-is if it doesn't match expected patterns
  };
  
  const formatCurrency = (amount) => {
    if (!amount || isNaN(amount)) return null;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(amount);
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";
    return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });
  };
  
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Q1 ",
    "Apr",
    "May",
    "Jun",
    "Q2 ",
    "Jul",
    "Aug",
    "Sep",
    "Q3 ",
    "Oct",
    "Nov",
    "Dec",
    "Q4 ",
    "YTD ",
  ];

  const monthLabels = [
    "Jan", "Feb", "Mar",  // index 0,1,2
    "Apr", "May", "Jun",  // index 3,4,5
    "Jul", "Aug", "Sep",  // index 6,7,8
    "Oct", "Nov", "Dec",  // index 9,10,11
  ];

  const previousYear = currentYear - 1;

  const groupByMonthAndYear = (data, dateField) => {
    return data.reduce((acc, item) => {
      const date = new Date(item[dateField]);
      const month = date.getMonth();
      const year = date.getFullYear();

      if (!acc[year]) acc[year] = Array(12).fill(0);
      acc[year][month] += 1; // Increment the count for that month

      return acc;
    }, {});
  };

  const groupTotalHiresByMonthAndYear = (data, dateField) => {
    return data.reduce((acc, row) => {
      const date = new Date(row[dateField]);
      const month = date.getMonth();
      const year = date.getFullYear();
      if (!acc[year]) acc[year] = Array(12).fill(0);
      acc[year][month] += parseFloat(row.Total_Hires) || 0;
      return acc;
    }, {});
  };
  
  const calculateQuarterSums = (monthlyData) => {
    const q1 = monthlyData.slice(0, 3).reduce((sum, value) => sum + value, 0);
    const q2 = monthlyData.slice(3, 6).reduce((sum, value) => sum + value, 0);
    const q3 = monthlyData.slice(6, 9).reduce((sum, value) => sum + value, 0);
    const q4 = monthlyData.slice(9, 12).reduce((sum, value) => sum + value, 0);
    const total = q1 + q2 + q3 + q4;

    return [...monthlyData.slice(0, 3), q1, ...monthlyData.slice(3, 6), q2, ...monthlyData.slice(6, 9), q3, ...monthlyData.slice(9, 12), q4, total];
  };

  const processAlpData = (data, userRole, selectedAlpTab) => {
    // For SA, if user clicks on LVL_3 tab, use LVL_2_NET (as SA is level 2)
    const column =
      selectedAlpTab === "LVL_1"
        ? "LVL_1_NET"
        : userRole === "SA"
        ? "LVL_2_NET"
        : "LVL_3_NET";
  
    const groupedData = data.reduce((acc, row) => {
      const [month, year] = row.month.split("/").map(Number);
      const adjustedMonth = month - 1;
      if (!acc[year]) acc[year] = Array(12).fill(0);
      const value = parseFloat(row[column]?.replace(/[^\d.-]/g, "")) || 0;
      // If the cell is 0 or the row comes from MGA, update with the value
      if (acc[year][adjustedMonth] === 0 || row.CL_Name === "MGA") {
        acc[year][adjustedMonth] = value;
      }
      return acc;
    }, {});
  
    return groupedData;
  };
  
  const processSubmittingAgentCount = (data) => {
  
    const groupedData = data.reduce((acc, row) => {
      const [month, year] = row.month.split("/").map(Number); // Parse "mm/yyyy" format
      const adjustedMonth = month - 1; // Convert 1-based month to 0-based index
  
      if (!acc[year]) {
        acc[year] = Array(12).fill(0); // Initialize year with empty months
      }
  
      acc[year][adjustedMonth] += 1; // Increment the count for the corresponding month

  
      return acc;
    }, {});
  
    return groupedData;
  };
  
  const processSubagentData = (data) => {
    // Group data by year and by month (each month gets a Set to collect unique names)
    const groupedData = {};
  
    data.forEach(row => {
      // Validate the month format
      if (!row.month || typeof row.month !== "string" || !row.month.includes("/")) {
        return;
      }
  
      const [monthStr, yearStr] = row.month.split("/");
      const month = Number(monthStr);
      const year = Number(yearStr);
      if (!month || !year) {
        return;
      }
  
      const adjustedMonth = month - 1; // Convert to 0-index
      if (adjustedMonth < 0 || adjustedMonth > 11) {
        return;
      }
  
      // Initialize the year if it doesn't exist
      if (!groupedData[year]) {
        groupedData[year] = Array.from({ length: 12 }, () => new Set());
      }
  
      if (row.LagnName) {
        groupedData[year][adjustedMonth].add(row.LagnName);
      }
    });
  
    // Convert monthly sets to counts and compute unique counts for quarters and YTD
    const result = {};
  
    Object.keys(groupedData).forEach(year => {
      const monthlySets = groupedData[year];
      const monthlyCounts = monthlySets.map(set => set.size);
  
      // Compute unique names for each quarter using set unions
      const q1Set = new Set([...monthlySets[0], ...monthlySets[1], ...monthlySets[2]]);
      const q2Set = new Set([...monthlySets[3], ...monthlySets[4], ...monthlySets[5]]);
      const q3Set = new Set([...monthlySets[6], ...monthlySets[7], ...monthlySets[8]]);
      const q4Set = new Set([...monthlySets[9], ...monthlySets[10], ...monthlySets[11]]);
      const ytdSet = new Set([
        ...monthlySets[0], ...monthlySets[1], ...monthlySets[2],
        ...monthlySets[3], ...monthlySets[4], ...monthlySets[5],
        ...monthlySets[6], ...monthlySets[7], ...monthlySets[8],
        ...monthlySets[9], ...monthlySets[10], ...monthlySets[11]
      ]);
  
      // Build the array to match your table structure:
      // - Indexes 0-2: monthly counts for Jan, Feb, Mar
      // - Index 3: Q1 unique count
      // - Indexes 4-6: monthly counts for Apr, May, Jun
      // - Index 7: Q2 unique count
      // - Indexes 8-10: monthly counts for Jul, Aug, Sep
      // - Index 11: Q3 unique count
      // - Indexes 12-14: monthly counts for Oct, Nov, Dec
      // - Index 15: Q4 unique count
      // - Index 16: YTD unique count
      const resultArray = [
        monthlyCounts[0],
        monthlyCounts[1],
        monthlyCounts[2],
        q1Set.size,
        monthlyCounts[3],
        monthlyCounts[4],
        monthlyCounts[5],
        q2Set.size,
        monthlyCounts[6],
        monthlyCounts[7],
        monthlyCounts[8],
        q3Set.size,
        monthlyCounts[9],
        monthlyCounts[10],
        monthlyCounts[11],
        q4Set.size,
        ytdSet.size
      ];
  
      result[year] = resultArray;
    });
  
    return result;
  };
  
  
  
  const fetchData = async () => {
    try {
      setLoading(true);
      const userRole = localStorage.getItem("userRole")?.toUpperCase();
      const queryRole = (userRole === "RGA" || userRole === "SGA") ? "MGA" : userRole;
      const agnName = localStorage.getItem("agnName");
  
      if (!agnName) {
        throw new Error("Invalid user role or agnName");
      }
  
      // For MGA/RGA use selectedMga if available, for SGA use dropdown value if selected; otherwise use stored agnName.
      const effectiveAgnName =
        (userRole === "MGA" || userRole === "RGA" || (userRole === "SGA" && selectedMga))
          ? selectedMga || agnName
          : agnName;
  
      // If userRole is SGA and no dropdown value is selected, use the SGA endpoints.
      const useSgaEndpoints = ["SGA", "MGA", "RGA"].includes(userRole) && !selectedMga;

  
      const requests = [];
  
      if (useSgaEndpoints) {
        // For ALP, call the summed route that returns the sum of LVL_1_NET per month
        requests.push(
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/monthly-alp-by-sga?value=${effectiveAgnName}`
          )
        );
        requests.push(
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/vips-sga?column=${queryRole}&value=${effectiveAgnName}`
          )
        );
        requests.push(
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/associates-sga?column=${queryRole}&value=${effectiveAgnName}`
          )
        );
        requests.push(
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/org-total-hires?value=${effectiveAgnName}`
          )
        );
        requests.push(
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/subagent-alp-sga?value=${effectiveAgnName}`
          )
        );
      } else {
        // Use original endpoints for ALP and other data
        requests.push(
          axios.get(
            `https://ariaslogin-4a95935f6093.herokuapp.com/api/monthly-alp-by-mga?value=${effectiveAgnName}`
          )
        );
        if (queryRole !== "AGT") {
          requests.push(
            axios.get(
              `https://ariaslogin-4a95935f6093.herokuapp.com/api/vips/multiple?value=${effectiveAgnName}`
            )
          );
          requests.push(
            axios.get(
              `https://ariaslogin-4a95935f6093.herokuapp.com/api/associates/multiple?value=${effectiveAgnName}`
            )
          );
          requests.push(
            axios.get(
              `https://ariaslogin-4a95935f6093.herokuapp.com/api/total-hires?value=${effectiveAgnName}`
            )
          );
          requests.push(
            axios.get(
              `https://ariaslogin-4a95935f6093.herokuapp.com/api/subagent-alp?value=${effectiveAgnName}`
            )
          );
        }
      }
  
      const responses = await Promise.all(requests);
      // Process ALP data: Handle SGA vs non-SGA endpoints separately
      if (useSgaEndpoints) {
        const alpResponse = responses[0];
        const sgaAlpData = alpResponse?.data?.data || [];
        // Group SGA ALP data into an object with years as keys and an array of 12 months as values
        const groupedSgaAlpData = sgaAlpData.reduce((acc, item) => {
          // Expect month format to be "mm/yyyy"
          const parts = item.month.split("/");
          if (parts.length < 2) {
            return acc;
          }
          const monthIndex = parseInt(parts[0], 10) - 1; // Convert month to 0-indexed
          const year = parseInt(parts[1], 10);
          if (!acc[year]) acc[year] = Array(12).fill(0);
          acc[year][monthIndex] += parseFloat(item.total);
          return acc;
        }, {});
        setAlpData(groupedSgaAlpData);
      } else {
        const alpResponse = responses[0];
        const rawData = alpResponse?.data?.data || [];
        setRawAlpData(rawData);
        setAlpData(processAlpData(rawData, queryRole, selectedAlpTab));
      }
  
      if (useSgaEndpoints) {
        // Process SGA-specific responses
        setVipsData(responses[1]?.data?.data || []);
        setAssociatesData(responses[2]?.data?.data || []);
        const hiresGrouped = groupTotalHiresByMonthAndYear(
          responses[3]?.data?.data || [],
          "MORE_Date"
        );
        setHiresData(hiresGrouped);
        
        const subAgentGrouped = processSubagentData(responses[4]?.data?.data || []);
        setSubAgentData(subAgentGrouped);
        
      } else {
        // Process responses for non-SGA endpoints
        if (queryRole !== "AGT") {
          setVipsData(responses[1]?.data?.data || []);
          setAssociatesData(responses[2]?.data?.data || []);
          const hiresGrouped = groupTotalHiresByMonthAndYear(
            responses[3]?.data?.data || [],
            "MORE_Date"
          );
          setHiresData(hiresGrouped);
          
          const subAgentGrouped = processSubagentData(responses[4]?.data?.data || []);
          setSubAgentData(subAgentGrouped);
          
        }
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data.");
    } finally {
      setLoading(false);
    }
  };
  
  
  const filterHierarchyData = (data, view) => {
    if (view === "a") {
      const logs = [];
      const yearsInUse = [currentYear, lastYear];
      const filtersByYear = {};
  
      yearsInUse.forEach((year) => {
        filtersByYear[year] = [];
      });
  
      const filteredData = data.filter((entry) => {
        const entryYear = new Date(entry.start).getFullYear();
        const isWithinCurrentYears = entryYear === currentYear || entryYear === lastYear;
        // Use effectiveAgnName here instead of agnName:
        const isLagnnameMatch = entry.lagnname === effectiveAgnName;
        const isRgaMatchAndRecent = entry.rga === effectiveAgnName && isWithinCurrentYears;
  
        if (isLagnnameMatch) {
          filtersByYear[entryYear].push({ reason: "lagnname matches effectiveAgnName", entry });
          logs.push(`Included: ${entry.lagnname} (Reason: lagnname matches effectiveAgnName)`);
          return true;
        }
  
        if (isRgaMatchAndRecent) {
          filtersByYear[entryYear].push({
            reason: "rga matches effectiveAgnName and start date is within the current years",
            entry,
          });
          logs.push(`Included: ${entry.lagnname} (Reason: rga matches effectiveAgnName and start date ${entry.start} is within the current years)`);
          return true;
        }
  
        logs.push(`Excluded: ${entry.lagnname}`);
        return false;
      });
  
      setFilteredHierarchyData(filteredData);
  
      return { yearsInUse, filtersByYear };
    } else {
      setFilteredHierarchyData(data);
    }
  };
  
  
  const handleViewChange = (view) => {
    setSelectedView(view);
    filterHierarchyData(hierarchyData, view); // Apply filtering based on the selected view
  };

  const handleCellClick = (tableName, dataItem, month, year) => {
    if (dataItem) {
      setSelectedTableDetails((prev) => ({
        ...prev,
        [tableName]: { data: dataItem, month, year }, // Store data and additional details
      }));
      
    }
  };
  useEffect(() => {
    if (rawAlpData.length > 0) {
      setAlpData(processAlpData(rawAlpData, queryRole, selectedAlpTab));
    }
  }, [rawAlpData, selectedAlpTab, queryRole]);
  
  useEffect(() => {
    fetchData();
  }, [selectedMga]); 
  
  if (loading) return <Placeholder/>;
  if (error) return <div>Error: {error}</div>;

  const renderSummedTable = (title, data, dateField, isGrouped = false, isCurrency = true) => {
    const currentDetails = selectedTableDetails[title];
  
    if (currentDetails) {
      const { data: detailData, month: monthInfo, year } = currentDetails;
  
      if (title === "VIPs") {
        const groupedData = (Array.isArray(detailData) ? detailData : [detailData]).reduce(
          (acc, item) => {
            const name = item.lagnname || "Unknown";
            if (!acc[name]) acc[name] = [];
            acc[name].push(item);
            return acc;
          },
          {}
        );
  
        return (
          <section>
            <h5>{`${title} > ${monthInfo.label} '${String(year).slice(-2)}`}</h5>
            <button
              className="insured-button"
              onClick={() =>
                setSelectedTableDetails((prev) => {
                  const updated = { ...prev };
                  delete updated[title];
                  return updated;
                })
              }
            >
              Back
            </button>
            <div className="details-view">
              {Object.entries(groupedData).map(([lagnname, entries], index) => (
                <table className="hierarchyTable" key={index}>
                  <thead>
                    <tr>
                      <th colSpan={2}>{formatName(lagnname) || "N/A"}</th>
                      <th colSpan={2}>Hierarchy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries.map((entry, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <th>Code Date</th>
                          <td>{formatDate(entry?.softcode_date) || "N/A"}</td>
                          <th style={{ backgroundColor: "#68B675" }}>MGA</th>
                          <td>{formatName(entry?.mga) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Gross ALP</th>
                          <td>{formatCurrency(entry?.gs) || "N/A"}</td>
                          <th style={{ backgroundColor: "#ED722F" }}>GA</th>
                          <td>{formatName(entry?.ga) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Count</th>
                          <td>{entry?.count || "N/A"}</td>
                          <th style={{ backgroundColor: "#B25271" }}>SA</th>
                          <td>{formatName(entry?.sa) || "N/A"}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </section>
        );
      }
  
      if (title === "Codes") {
        const groupedData = (Array.isArray(detailData) ? detailData : [detailData]).reduce(
          (acc, item) => {
            const name = item.LagnName || "Unknown";
            if (!acc[name]) acc[name] = [];
            acc[name].push(item);
            return acc;
          },
          {}
        );
  
        return (
          <section>
            <h5>{`${title} > ${monthInfo.label} '${String(year).slice(-2)}`}</h5>
            <button
              className="insured-button"
              onClick={() =>
                setSelectedTableDetails((prev) => {
                  const updated = { ...prev };
                  delete updated[title];
                  return updated;
                })
              }
            >
              Back
            </button>
            <div className="details-view">
              {Object.entries(groupedData).map(([lagnName, entries], index) => (
                <table className="hierarchyTable" key={index}>
                  <thead>
                    <tr>
                      <th colSpan={2}>{formatName(lagnName)}</th>
                      <th colSpan={2}>Hierarchy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entries.map((entry, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <th>Prod Date</th>
                          <td>{formatDate(entry?.PRODDATE) || "N/A"}</td>
                          <th style={{ backgroundColor: "#68B675" }}>MGA</th>
                          <td>{formatName(entry?.MGA) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th></th>
                          <td></td>
                          <th style={{ backgroundColor: "#ED722F" }}>GA</th>
                          <td>{formatName(entry?.GA) || "N/A"}</td>
                        </tr>
                        <tr>
                          <th></th>
                          <td></td>
                          <th style={{ backgroundColor: "#B25271" }}>SA</th>
                          <td>{formatName(entry?.SA) || "N/A"}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </section>
        );
      }
    }
  
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    const monthIndexToDataIndex = (index) => {
      switch (index) {
        case 0:
          return { isMonth: true, monthIndex: 0, label: "Jan" };
        case 1:
          return { isMonth: true, monthIndex: 1, label: "Feb" };
        case 2:
          return { isMonth: true, monthIndex: 2, label: "Mar" };
        case 3:
          return { isMonth: false, label: "Q1" };
        case 4:
          return { isMonth: true, monthIndex: 3, label: "Apr" };
        case 5:
          return { isMonth: true, monthIndex: 4, label: "May" };
        case 6:
          return { isMonth: true, monthIndex: 5, label: "Jun" };
        case 7:
          return { isMonth: false, label: "Q2" };
        case 8:
          return { isMonth: true, monthIndex: 6, label: "Jul" };
        case 9:
          return { isMonth: true, monthIndex: 7, label: "Aug" };
        case 10:
          return { isMonth: true, monthIndex: 8, label: "Sep" };
        case 11:
          return { isMonth: false, label: "Q3" };
        case 12:
          return { isMonth: true, monthIndex: 9, label: "Oct" };
        case 13:
          return { isMonth: true, monthIndex: 10, label: "Nov" };
        case 14:
          return { isMonth: true, monthIndex: 11, label: "Dec" };
        case 15:
          return { isMonth: false, label: "Q4" };
        case 16:
          return { isMonth: false, label: "YTD" };
        default:
          return { isMonth: false, label: "" };
      }
    };
  
    const handleCellClickWrapper = (dataItem, monthInfo, year) => {
      if (!monthInfo.isMonth) return;
      handleCellClick(title, dataItem, monthInfo, year);
    };
  
    let currentYearData = [];
    let previousYearData = [];
  
    if (isGrouped) {
      currentYearData = data[currentYear] || Array(12).fill(0);
      previousYearData = data[previousYear] || Array(12).fill(0);
    } else {
      const groupedData = groupByMonthAndYear(data, dateField);
      currentYearData = groupedData[currentYear] || Array(12).fill(0);
      previousYearData = groupedData[previousYear] || Array(12).fill(0);
    }
  
    // For "Submitting Agent Count", the processed data already includes quarter and YTD values.
    let currentYearWithQuarters, previousYearWithQuarters;
    if (title === "Submitting Agent Count") {
      currentYearWithQuarters = currentYearData;
      previousYearWithQuarters = previousYearData;
    } else {
      currentYearWithQuarters = calculateQuarterSums(currentYearData);
      previousYearWithQuarters = calculateQuarterSums(previousYearData);
    }
  
    const growthData = currentYearWithQuarters.map((value, index) => {
      const previousValue = previousYearWithQuarters[index];
      const growth = value - previousValue;
      return {
        value: growth,
        className: growth > 0 ? "growth-positive" : growth < 0 ? "growth-negative" : "",
      };
    });
  
    return (
      <section>
        <h5>{title}</h5>
        <div className="recruiting-table-container">
          <table className="scorecard-table">
            <thead>
              <tr>
                <th>Year</th>
                {months.map((month, index) => (
                  <th
                    key={index}
                    className={
                      index === 3 || index === 7 || index === 11 || index === 15
                        ? "quarter-column"
                        : index === 16
                        ? "year-total-column"
                        : ""
                    }
                  >
                    {month}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{previousYear}</td>
                {previousYearWithQuarters.map((value, index) => {
                  const monthInfo = monthIndexToDataIndex(index);
                  const isQuarterCell = !monthInfo.isMonth;
                  const validData = Array.isArray(data) ? data : [];
                  const dataItem = monthInfo.isMonth
                    ? validData.filter((item) => {
                        const itemDate =
                          title === "VIPs"
                            ? new Date(item.vip_month)
                            : title === "Codes"
                            ? new Date(item.PRODDATE)
                            : null;
                        return (
                          itemDate &&
                          itemDate.getMonth() === monthInfo.monthIndex &&
                          itemDate.getFullYear() === previousYear
                        );
                      })
                    : null;
                  const isClickable = dataItem && dataItem.length > 0;
  
                  return (
                    <td
                      key={index}
                      className={`
                        ${isQuarterCell ? "quarter-column" : ""}
                        ${index === 16 ? "year-total-column" : ""}
                        ${isClickable ? "has-data" : ""}
                      `}
                      title={isClickable ? "Details available" : ""}
                      onClick={isClickable ? () => handleCellClickWrapper(dataItem, monthInfo, previousYear) : null}
                    >
                      {isCurrency ? currencyFormatter.format(value) : value}
                    </td>
                  );
                })}
              </tr>
              <tr>
                <td>{currentYear}</td>
                {currentYearWithQuarters.map((value, index) => {
                  const monthInfo = monthIndexToDataIndex(index);
                  const isQuarterCell = !monthInfo.isMonth;
                  const validData = Array.isArray(data) ? data : [];
                  const dataItem = monthInfo.isMonth
                    ? validData.filter((item) => {
                        const itemDate =
                          title === "VIPs"
                            ? new Date(item.vip_month)
                            : title === "Codes"
                            ? new Date(item.PRODDATE)
                            : null;
                        return (
                          itemDate &&
                          itemDate.getMonth() === monthInfo.monthIndex &&
                          itemDate.getFullYear() === currentYear
                        );
                      })
                    : null;
                  const isClickable = dataItem && dataItem.length > 0;
  
                  return (
                    <td
                      key={index}
                      className={`
                        ${isQuarterCell ? "quarter-column" : ""}
                        ${index === 16 ? "year-total-column" : ""}
                        ${isClickable ? "has-data" : ""}
                      `}
                      title={isClickable ? "Details available" : ""}
                      onClick={isClickable ? () => handleCellClickWrapper(dataItem, monthInfo, currentYear) : null}
                    >
                      {isCurrency ? currencyFormatter.format(value) : value}
                    </td>
                  );
                })}
              </tr>
              <tr className="growth-row">
                <td>Growth</td>
                {growthData.map(({ value, className }, index) => (
                  <td key={index} className={className}>
                    {isCurrency ? currencyFormatter.format(value) : value}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
      </section>
    );
  };
  
  
  return (
      <div className="scorecard">
<div className="header-row">
{["SA", "GA", "MGA", "RGA"].includes(userRole) && (
  <div className="tabs">
  <input
    type="radio"
    id="lvl1"
    name="alp_view"
    value="LVL_1"
    checked={selectedAlpTab === "LVL_1"}
    onChange={() => setSelectedAlpTab("LVL_1")}
  />
  <label htmlFor="lvl1">LVL 1</label>
  <input
    type="radio"
    id="lvl3"
    name="alp_view"
    value="LVL_3"
    checked={selectedAlpTab === "LVL_3"}
    onChange={() => setSelectedAlpTab("LVL_3")}
  />
  <label htmlFor="lvl3">LVL 3</label>
</div>

    )}
  {allowedRoles.includes(userRole) && (
    <div className="mga-dropdown">
      <label htmlFor="mga-select">Select MGA: </label>
      <select
  id="mga-select"
  value={selectedMga}
  onChange={(e) => setSelectedMga(e.target.value)}
>
  {showAriasOrganization && (
    <option value="">ARIAS ORGANIZATION</option>
  )}
  {uniqueMgas.map((mga, index) => (
    <option key={index} value={mga.lagnname}>
      {mga.lagnname}
    </option>
  ))}
</select>

    </div>
  )}

  <div className="year-navigation">
    <button className="arrow-change-button" onClick={() => navigateYears("back")}>
      ←
    </button>
    <span>
      {lastYear} & {currentYear}
    </span>
    <button
      className="arrow-change-button"
      onClick={() => navigateYears("forward")}
      disabled={currentYear === new Date().getFullYear()}
    >
      →
    </button>
  </div>
</div>


        <h1></h1>
        {/* Commenting out the view toggle for RGA
        {userRole === "RGA" && (
          <div className="view-toggle">
            <button
              className={selectedView === "a" ? "active" : ""}
              onClick={() => handleViewChange("a")}
            >
              View A
            </button>
            <button
              className={selectedView === "b" ? "active" : ""}
              onClick={() => handleViewChange("b")}
            >
              View B
            </button>
          </div>
        )} */}
          <>
            {userRole !== "AGT" && renderSummedTable("VIPs", vipsData, "vip_month", false, false)}
            {userRole !== "AGT" && renderSummedTable("Codes", associatesData, "PRODDATE", false, false)}
            {renderSummedTable("ALP", alpData, null, true, true)}
            {userRole !== "AGT" &&
              renderSummedTable("Hires", hiresData, "MORE_Date", true, false)}
            {userRole !== "AGT" &&
              renderSummedTable(
                "Submitting Agent Count",
                subAgentData,
                null,
                true,
                false
              )}
          </>
      
      </div>
  );
  
  
  
};

export default Scorecard;
